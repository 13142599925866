import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let ButtonComponent = class ButtonComponent extends Vue {
};
__decorate([
    Prop({ default: '' })
], ButtonComponent.prototype, "propTitle", void 0);
__decorate([
    Prop({ default: '' })
], ButtonComponent.prototype, "propTo", void 0);
__decorate([
    Prop({ default: 'color: white' })
], ButtonComponent.prototype, "propStyle", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propDisabled", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propBlock", void 0);
__decorate([
    Prop({ default: null })
], ButtonComponent.prototype, "propHeight", void 0);
__decorate([
    Prop({ default: () => () => { } })
], ButtonComponent.prototype, "propClick", void 0);
__decorate([
    Prop({ default: 'blue300' })
], ButtonComponent.prototype, "propColor", void 0);
__decorate([
    Prop({ default: 'white' })
], ButtonComponent.prototype, "propIconLeftColor", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propLarge", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propSmall", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propXSmall", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propLoading", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propOutlined", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propIconLeft", void 0);
__decorate([
    Prop({ default: false })
], ButtonComponent.prototype, "propIconRight", void 0);
__decorate([
    Prop({ default: 'white' })
], ButtonComponent.prototype, "propColorRight", void 0);
__decorate([
    Prop({ default: 'articulat-bold' })
], ButtonComponent.prototype, "propClass", void 0);
ButtonComponent = __decorate([
    Component
], ButtonComponent);
export default ButtonComponent;
