var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.entityObject)?_c('v-card-text',{staticClass:"card-text-border"},[_c('div',{staticClass:"articulat-bold pb-2 preencha-info"},[_vm._v(" Preencha as informações ")]),_c('ValidationProvider',{attrs:{"debounce":100,"name":"Unidade","vid":"companySelected","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Unidade: ")]),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Unidade","items":_vm.companies,"item-text":"nome_fantasia","item-value":"id","error-messages":errors,"loading":_vm.loadingCompany,"rounded":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [(typeof message === 'string')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("info")]):_vm._e(),(typeof message === 'string')?_c('span',[_vm._v(_vm._s(message))]):_vm._e()]}}],null,true),model:{value:(_vm.companySelected),callback:function ($$v) {_vm.companySelected=$$v},expression:"companySelected"}})],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"debounce":100,"name":"Nome do usuário","vid":"user_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Responsável do setor: ")]),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Nome do usuário","items":_vm.colaboradorItems,"item-text":"full_name","item-value":"id","error-messages":errors,"rounded":"","outlined":"","dense":"","loading":_vm.loadingUser},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [(typeof message === 'string')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("info")]):_vm._e(),(typeof message === 'string')?_c('span',[_vm._v(_vm._s(message))]):_vm._e()]}}],null,true),model:{value:(_vm.entityObject.user_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "user_id", $$v)},expression:"entityObject.user_id"}})],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"debounce":100,"name":"Assunto","vid":"subject_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Tipo de ticket: ")]),_c('v-col',[_c('v-select',{attrs:{"label":"Selecione o tipo do seu problema","items":_vm.subject_options,"error-messages":errors,"item-text":"value","item-value":"id","rounded":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [(typeof message === 'string')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("info")]):_vm._e(),(typeof message === 'string')?_c('span',[_vm._v(_vm._s(message))]):_vm._e()]}}],null,true),model:{value:(_vm.entityObject.subject_id),callback:function ($$v) {_vm.$set(_vm.entityObject, "subject_id", $$v)},expression:"entityObject.subject_id"}})],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"debounce":100,"name":"Título","vid":"title","rules":{ required: true, counter: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Título do ticket: ")]),_c('v-col',[_c('v-text-field',{attrs:{"label":"Título","error-messages":errors,"rounded":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [(typeof message === 'string')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("info")]):_vm._e(),(typeof message === 'string')?_c('span',[_vm._v(_vm._s(message))]):_vm._e()]}}],null,true),model:{value:(_vm.entityObject.title),callback:function ($$v) {_vm.$set(_vm.entityObject, "title", $$v)},expression:"entityObject.title"}})],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"debounce":100,"name":"Descrição","vid":"description","rules":{ required: true, counter: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Descrição: ")]),_c('v-col',[_c('v-textarea',{attrs:{"placeholder":"Descreva seu problema de forma concisa e direta, incluindo todas as informações relevantes. Detalhe o que deseja realizar, por quais motivos e os resultados que espera alcançar. Essa abordagem é crucial para fornecer contexto ao nosso time de desenvolvimento, permitindo um direcionamento mais eficaz e evitando erros e retrabalhos.","error-messages":errors,"rounded":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [(typeof message === 'string')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("info")]):_vm._e(),(typeof message === 'string')?_c('span',[_vm._v(_vm._s(message))]):_vm._e()]}}],null,true),model:{value:(_vm.entityObject.description),callback:function ($$v) {_vm.$set(_vm.entityObject, "description", $$v)},expression:"entityObject.description"}})],1)],1)]}}],null,true)}),_c('span',[_vm._v("Em casos de bugs ou mal funcionamento, favor descrever o passo a passo de como o problema ocorreu. ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Anexo: ")]),_c('v-col',[_c('UploadFilesComponent',{attrs:{"propCardTitle":"Faça aqui o upload de anexos para complementar a descrição","propRelatedTableName":"","propHideUploadBtn":true,"propHideTable":true,"propDense":"","propRounded":"","propOutlined":""},model:{value:(_vm.comprovantesUploadFiles),callback:function ($$v) {_vm.comprovantesUploadFiles=$$v},expression:"comprovantesUploadFiles"}})],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"px-0"},[_c('v-spacer'),_c('ButtonComponent',{staticClass:"pr-2",attrs:{"propTitle":"Cancelar e voltar","propOutlined":"","propColor":"blue300","propClick":_vm.handleClose}}),_c('ButtonComponent',{attrs:{"propClick":_vm.submit,"propDisabled":!valid,"propTitle":"Enviar ticket","propLoading":_vm.loadingBtn}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }