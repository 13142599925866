import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import _ from 'lodash';
import { helpdeskStore, mainStore, companyStore, userStore, } from '@/utils/store-accessor';
import ButtonComponent from '@/components/ButtonComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import { newTicket } from '@/interfaces/ticket';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('counter', {
    getMessage: (field) => `${field} mínimo de 5 caracteres`,
    validate: async (value) => !!(value.length >= 5),
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.entityObject = null;
        this.comprovantesUploadFiles = [];
        this.loadingCompany = false;
        this.loadingUser = false;
        this.loadingBtn = false;
        this.companySelected = null;
        this.subject_options = [
            { id: 1, value: 'Dúvidas' },
            { id: 2, value: 'Novo módulo/funcionalidade' },
            { id: 3, value: 'Melhoria' },
            { id: 4, value: 'Bug/Erro' },
            { id: 5, value: 'Problema com pedido' },
            { id: 6, value: 'Conciliação Bancária' },
            { id: 7, value: 'Importação SPONTE' },
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    handleClose() {
        this.entityObject = null;
        this.$emit('on-close');
    }
    get companies() {
        return _.orderBy(companyStore.companies, 'nome_fantasia', 'asc');
    }
    get colaboradorItems() {
        return _.orderBy(userStore.users, 'full_name', 'asc');
    }
    async onPropData(company_id) {
        await userStore.getUserByCompanyId({ company_id, somente_ativo: true });
    }
    async submit() {
        this.loadingBtn = true;
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.loadingBtn = false;
            return;
        }
        this.entityObject.company_id = this.companySelected;
        const responseData = await helpdeskStore.createHelpdeskTickets({
            data: this.entityObject,
            files: this.comprovantesUploadFiles,
        });
        this.loadingBtn = false;
        if (responseData) {
            this.$emit('on-confirm');
        }
    }
    async mounted() {
        this.entityObject = newTicket();
        this.loadingCompany = true;
        await companyStore.getCompanies();
        this.loadingCompany = false;
    }
};
__decorate([
    Watch('companySelected')
], Create.prototype, "onPropData", null);
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ButtonComponent,
            UploadFilesComponent,
        },
    })
], Create);
export default Create;
