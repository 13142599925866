export function newTicket() {
    return {
        company_id: null,
        user_id: '',
        title: '',
        description: '',
        origin: 'drive',
        subject_id: null
    };
}
